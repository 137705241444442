import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

function Seo({ description, lang, meta, title, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            social {
              twitter
            }
          }
        }
      }
    `
  );

  const metaTitle = title || site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;
  const metaImage = image || `${site.siteMetadata.siteUrl}/favicon.png`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      style={[
        {
          cssText: `
            @font-face{
                font-family:"Sabon ET";
                font-style: italic;
                src:url("/fonts/1190934/ec595c98-49a2-4f80-bbc5-01a8ad28b526.woff2") format("woff2"),url("/fonts/1190934/89d9019b-be0f-4f4a-ac69-a68ea4da7c55.woff") format("woff");
                font-display: swap;
            }
            @font-face{
                font-family:"Sabon ET";
                src:url("/fonts/1190938/ef12a52c-a47f-49fe-b5bb-ee15aa7b5377.woff2") format("woff2"),url("/fonts/1190938/13b0a4ee-3238-4837-bb6e-162ae2990401.woff") format("woff");
                font-display: swap;
            }
            @font-face{
                font-family:"Trade Gothic";
                src:url("/fonts/5730936/ee3d290f-f853-4ff3-913e-7bae693a43ec.woff2") format("woff2"),url("/fonts/5730936/13f010e4-8178-40a5-a790-11a6532a964c.woff") format("woff");
                font-display: swap;
            }
            @font-face{
                font-family:"Trade Gothic";
                font-weight: bold;
                src:url("/fonts/5730950/dae937ec-159f-4710-9b2d-9470e24807ba.woff2") format("woff2"),url("/fonts/5730950/c8856bf2-4d23-4e30-9fb1-eede15b787b6.woff") format("woff");
                font-display: swap;
            }
        `,
        },
      ]}
    />
  );
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  image: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  image: PropTypes.string,
};

export default Seo;
